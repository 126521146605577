import React, { useContext, useState } from 'react';
import { LanguageContext } from '../Main/LanguageContext';
import translations from '../../Translations';
import '../../styles/Contact.css';

import Lottie from 'lottie-react';


import successAnimation from '../../assets/success.json';

import '../../firebase';
import { addDoc, collection, getFirestore } from 'firebase/firestore';
import Footer from "../../components/Main/Footer.js";
import OffcanvasExample from '../../components/Main/NavBar';

function EventWorkshop({ toggleDarkMode, toggleDarkTheme }) {

    const [NameInputvalue, setNameInputvalue] = useState('');
    const [EmailInputvalue, setEmailInputvalue] = useState('');
    const [whatsappInputvalue, setWhatsappInputvalue] = useState('');
    const [storeNameInputvalue, setStoreNameInputvalue] = useState('');
    const [linkInputvalue, setLinkInputvalue] = useState('');

    const [showPopup, setShowPopup] = useState(false);

    const db = getFirestore();
    // const setDataToFirestore = async (e) => {
    //     e.preventDefault();
    //     try {
    //         await addDoc(collection(db, "Event_Workshop"), {
    //             name: NameInputvalue,
    //             email: EmailInputvalue,
    //             whatsapp: whatsappInputvalue,
    //             storeName: storeNameInputvalue,
    //             link: linkInputvalue,
    //         });
    //         setShowPopup(true);
    //         setNameInputvalue('');
    //         setEmailInputvalue('');
    //         setWhatsappInputvalue('');
    //         setStoreNameInputvalue('');
    //         setLinkInputvalue('');


    //     } catch (error) {
    //         console.error("Error: ", error);
    //     }
    // };


    const setDataToFirestore = async (e) => {
        e.preventDefault();
        const formData = {
            name: NameInputvalue,
            email: EmailInputvalue,
            whatsapp: whatsappInputvalue,
            storeName: storeNameInputvalue,
            link: linkInputvalue,
        };
        
        try {
            // Send data to Firestore
            await addDoc(collection(db, "Event_Workshop"), formData);
    
            // Send data to Google Sheets via the Web App URL
            const googleSheetsUrl = 'https://docs.google.com/spreadsheets/d/1zHyuPXyRU4VKsTu-unY-6Ja9NI70OhuoCksj2dC8c1k/edit?gid=0#gid=0'; // The URL from Google Apps Script
            await fetch(googleSheetsUrl, {
                method: 'POST',
                body: JSON.stringify(formData),
                headers: {
                    'Content-Type': 'application/json',
                },
            });
    
            setShowPopup(true);
    
            // Clear form
            setNameInputvalue('');
            setEmailInputvalue('');
            setWhatsappInputvalue('');
            setStoreNameInputvalue('');
            setLinkInputvalue('');
        } catch (error) {
            console.error("Error: ", error);
        }
    };
    
    const handleClosePopup = () => {
        setShowPopup(false);
    };

    const { language } = useContext(LanguageContext);
    const t = translations[language];

    return (
        <>
            <OffcanvasExample toggleDarkMode={toggleDarkMode} toggleDarkTheme={toggleDarkTheme} />

            <div className={`contact-main marginTopEvent ${language === 'ar' ? 'arabic' : 'english'}`} id="contact">
                <div className="contact-container">
                    <h1 className="contact-title">
                        {t.eventTitle}
                        <span className="specialTxt" >{t.eventSpecialTxt}</span>
                    </h1>
                    <p className="contact-paragraph">{t.eventDescription}</p>

                    <div className="contact-form">
                        <form
                            method="POST"
                            className="contact-form"
                            onSubmit={setDataToFirestore}
                            dir={t.direction}>
                            <div className="form-row">
                                <input
                                    type="text"
                                    placeholder={t.EventNamePlaceholder}
                                    className={`form-input half-width ${toggleDarkMode ? 'bg-dark dark-contact' : ''}`}
                                    name="name"
                                    value={NameInputvalue}
                                    onChange={(e) => setNameInputvalue(e.target.value)}
                                    required
                                />
                                <input
                                    type="email"
                                    placeholder={t.eventEmailPlaceholder}
                                    className={`form-input half-width ${toggleDarkMode ? 'bg-dark dark-contact' : ''}`}
                                    name="email"
                                    value={EmailInputvalue}
                                    onChange={(e) => setEmailInputvalue(e.target.value)}
                                    required
                                />

                            </div>

                            <div className="form-row">

                                <input
                                    type="text"
                                    placeholder={t.eventWhatsappPlaceholder}
                                    className={`form-input half-width ${toggleDarkMode ? 'bg-dark dark-contact' : ''}`}
                                    name="whatsapp"
                                    value={whatsappInputvalue}
                                    onChange={(e) => setWhatsappInputvalue(e.target.value)}
                                    required
                                />

                                <input
                                    type="text"
                                    placeholder={t.eventStoreNamePlaceholder}
                                    className={`form-input half-width  ${toggleDarkMode ? 'bg-dark dark-contact' : ''}`}
                                    name="storeName"
                                    value={storeNameInputvalue}
                                    onChange={(e) => setStoreNameInputvalue(e.target.value)}
                                    required
                                />
                            </div>
                            <input
                                type="text"
                                placeholder={t.eventLinkPlaceholder}
                                className={`form-input ${toggleDarkMode ? 'bg-dark dark-contact' : ''}`}
                                name="link"
                                value={linkInputvalue}
                                onChange={(e) => setLinkInputvalue(e.target.value)}
                                required
                            />


                            <button type="submit" className="send-btn">
                                {t.sendButton}
                            </button>


                        </form>
                    </div>
                </div>

                {showPopup && (

                    <div className="popup-overlay">
                        <div className={`popup-card ${language === 'ar' ? 'arabic' : 'english'}`}>
                            <Lottie animationData={successAnimation} className="popup-icon" />
                            <p className="popup-message">{t.successMessage}</p>
                            <p className="popup-submessage">{t.successMessageTxt}</p>
                            <button className="popup-button" onClick={handleClosePopup}>{t.successMessageBtn}</button>
                        </div>
                    </div>
                )}
            </div>

            <Footer toggleDarkMode={toggleDarkMode} toggleDarkTheme={toggleDarkTheme} />

        </>

    );
}

export default EventWorkshop