import React, { useContext } from 'react';
import '../styles/Frequently.css';
import { LanguageContext } from './Main/LanguageContext';
import translations from '../Translations';
import Accordion from './Accordion';

const Frequently = () => {
    const { language } = useContext(LanguageContext);
    const t = translations[language].faq || translations[language];
    const direction = language === 'ar' ? 'rtl' : 'ltr';
    const langClass = language === 'ar' ? 'arabic' : 'english';

    const faqData = [
        { id: 1, questions: t.paymentMethodsStore.question, answer: t.paymentMethodsStore.answer },
        { id: 2, questions: t.marketplace.question, answer: t.marketplace.answer },
        { id: 3, questions: t.subscribeBuyer.question, answer: t.subscribeBuyer.answer.join(" ") },
        { id: 4, questions: t.paymentMethodsPurchase.question, answer: t.paymentMethodsPurchase.answer },
    ];

    return (
        <div className={`frq-main-container ${langClass}`} id="frequent" dir={direction}>
            <div className="container">
                <header>
                    <p className={`questions ${langClass}`}>{t.faqTitle} </p>
                    <p className="sectionFourTxt">{t.faqSpecial}</p>
                </header>
                <div className={`accordionBlock ${langClass}`} >
                    {faqData.map((val, index) => (
                        <Accordion 
                            key={val.id} 
                            {...val} 
                            isLast={index === faqData.length - 1} 
                        />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Frequently;
