import React, { useContext } from 'react';
// import { useNavigate } from 'react-router-dom';

// motion


import { LanguageContext } from './Main/LanguageContext';
import translations from '../Translations';
import '../styles/Mission.css';

// imgs
import valueIcon from '../assets/value.svg';
import visionIcon from '../assets/vision.svg';
import mtpIcon from '../assets/mtp.svg';

const missionCardsData = [
  { id: 1, imgSrc: valueIcon },
  { id: 2, imgSrc: visionIcon },
  { id: 3, imgSrc: mtpIcon }
];

const Mission = ({toggleDarkMode}) => {
  const { language } = useContext(LanguageContext);
  const t = translations[language];
  const langClass = language === 'ar' ? 'arabic' : 'english';

  // const navigate = useNavigate();


  // const goToContactPage = () => {
  //   navigate('/contact');
  // };
  
  return (
    <div className={`mission-container top-text ${langClass} ${toggleDarkMode ? 'background-dark' : 'background-light'}`}>
      <div className='container'>

        {/* <header>
          <h1 className="header-topic">
            {t.missionCardTitle}
            <span className="special-txt">{t.missionCardSpecial}</span>
          </h1>
          <p className='mission-card-txt'>{t.missionCardTxt}</p>
        </header> */}

        <div className={`mission-card-section`} dir={t.direction}>
          {missionCardsData.map((card, index) => (
            <div className="mission-card" key={card.id}>
              {/* <div className={`mission-card-border border-${index + 1}`}></div> */}
              <div className="mission-card-content">
                <div className="mission-card-image">
                  <img src={card.imgSrc} alt={`Card ${card.id}`} />
                </div>
                <h3 className="mission-card-title">{t.missionCards[index].title}</h3>
                <p className="mission-card-description">{t.missionCards[index].description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Mission;
