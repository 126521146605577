import React, { useContext, useState } from "react";
import PricingCard from "./PriceCard";
import "../styles/Packages.css";
import specialPackageImage from "../assets/special-package.png";
import { LanguageContext } from "./Main/LanguageContext";
import translations from "../Translations";

function Packages({  toggleDarkMode, toggleDarkTheme }) {
  const { language } = useContext(LanguageContext);
  const t = translations[language];
  const langClass = language === "ar" ? "arabic" : "english";
  const [isMonthly, setIsMonthly] = useState(true);

  const togglePlan = () => {
    setIsMonthly(!isMonthly);
  };

  return (
    <div className={`PricingApp ${langClass}`} id="pricing">
      <div className="app-container container">
        <header>
          <h1 className={`header-topic ${langClass}`}>{t.pricingCardTitle}</h1>
          <p className={`pricing-title-txt ${langClass}`}>{t.pricingCardTxt}</p>
        </header>

        {/* Toggle Button */}
        <div className="plan-toggle">
          <span className={`plan-option ${isMonthly ? "active" : ""}`}>{t.monthlyTitle}</span>
          <label className="switch">
            <input type="checkbox" onChange={togglePlan} checked={!isMonthly} />
            <span className="slider round"></span>
          </label>
          <span className={`plan-option ${!isMonthly ? "active" : ""}`}>{t.yearlyTitle}</span>
        </div>

        {/* Pricing Cards */}
        <div 
        
        className={`pricing-cards all-pricing`}>
          {isMonthly ? (
            <>
              <PricingCard
                packageKey="goldPackage"
                title={t.goldPackage.title}
                number={t.goldPackage.number}
                price={t.goldPackage.price}
                monthly={t.goldPackage.monthly}
                features={t.goldPackage.features}
                redFeatures={[]}  
                toggleDarkMode={toggleDarkMode}
              />
              <PricingCard
                packageKey="silverPackage"
                title={t.silverPackage.title}
                number={t.silverPackage.number}
                price={t.silverPackage.price}
                monthly={t.silverPackage.monthly}
                features={t.silverPackage.features}
                image={specialPackageImage}
                redFeatures={[9]}
                toggleDarkMode={toggleDarkMode}
              />
              <PricingCard
                packageKey="bronzePackage"
                title={t.bronzePackage.title}
                number={t.bronzePackage.number}
                price={t.bronzePackage.price}
                monthly={t.bronzePackage.monthly}
                features={t.bronzePackage.features}
                redFeatures={[8, 9]}
                toggleDarkMode={toggleDarkMode}
              />
            </>
          ) : (
            <>
              <PricingCard
                packageKey="platinumPackage"
                title={t.platinumPackage.title}
                number={t.platinumPackage.number}
                price={t.platinumPackage.price}
                monthly={t.platinumPackage.monthly}
                features={t.platinumPackage.features}
                redFeatures={[]}  
                toggleDarkMode={toggleDarkMode}
              />
              <PricingCard
                packageKey="diamondPackage"
                title={t.diamondPackage.title}
                number={t.diamondPackage.number}
                price={t.diamondPackage.price}
                monthly={t.diamondPackage.monthly}
                features={t.diamondPackage.features}
                image={specialPackageImage}
                redFeatures={[]}
                toggleDarkMode={toggleDarkMode}  
              />
              <PricingCard
                packageKey="pearlPackage"
                title={t.pearlPackage.title}
                number={t.pearlPackage.number}
                price={t.pearlPackage.price}
                monthly={t.pearlPackage.monthly}
                features={t.pearlPackage.features}
                redFeatures={[]} 
                toggleDarkMode={toggleDarkMode} 
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default Packages;
