import React, { useContext } from "react";
import { useLocation } from "react-router-dom";
import { MdCheckCircle, MdCancel } from "react-icons/md";
import { LanguageContext } from '../Main/LanguageContext';
import translations from '../../Translations';
import "../../styles/Checkout.css";
import Footer from "../Main/Footer";
import sadadImage from "../../assets/sadad2.png";
import tadawlImage from "../../assets/tadawl.svg";
// import moamalatImage from "../../assets/moamalat.png";
import paypalImage from "../../assets/paypal.png";
import mobiImage from "../../assets/mobi.png";
import viseImage from "../../assets/visa.png";
import edf3lyImage from "../../assets/edf3ly.png";

import OffcanvasExample from '../../components/Main/NavBar';


const Checkout = ({ toggleDarkMode, toggleDarkTheme }) => {
    const { language } = useContext(LanguageContext);
    const t = translations[language];
    const isArabic = language === "ar";
    const location = useLocation();
    const { packageKey } = location.state;

    // Retrieve package data from translations based on packageKey
    const packageData = t[packageKey] || {
        title: "Default Package",
        number: "N/A",
        price: "N/A",
        monthly: "",
        features: [],
        redFeatures: [] 
    };

    const { title, number, price, monthly, features, redFeatures } = packageData;

    return (
        <>
            <OffcanvasExample toggleDarkMode={toggleDarkMode} toggleDarkTheme={toggleDarkTheme} />

            <div className="container">
                <div className={`checkout-page ${isArabic ? 'arabic' : 'english'}`}>
                    <div className="payment-methods">
                        <h2 dir={t.direction}>{t.paymentMethods}</h2>
                        <div className="payment-method-labels" dir={t.direction}>
                        <label className="payment-label">
  <input type="radio" name="paymentMethod" value="vise" className="styled-input" />
  <img src={viseImage} alt="" className="payment-image" />
  {t.method1}
</label>

                            <label className="payment-label">
                                <input type="radio" name="paymentMethod" value="sadad" className="styled-input" />
                                <img src={sadadImage} alt="" className="payment-image" />
                                {t.method2}
                            </label>
                            <label className="payment-label">
                                <input type="radio" name="paymentMethod" value="edf3ly" className="styled-input" />
                                <img src={edf3lyImage} alt="" className="payment-image" />
                                {t.method3}
                            </label>
                            <label className="payment-label">
                                <input type="radio" name="paymentMethod" value="tadawl" className="styled-input"/>
                                <img src={tadawlImage} alt="" className="payment-image" />
                                {t.method4}
                            </label>
                        
                          

                            <label className="payment-label">
                                <input type="radio" name="paymentMethod" value="mobi" className="styled-input" />
                                <img src={mobiImage} alt="" className="payment-image" />
                                {t.method6}
                            </label>

                            <label className="payment-label">
                                <input type="radio" name="paymentMethod" value="paypal" className="styled-input"/>
                                <img src={paypalImage} alt="" className="payment-image" />
                                {t.method5}
                            </label>
                        
                        </div>
                    </div>

                    <div className={`selected-package ${isArabic ? 'arabic' : 'english'}`}>
                        <p className="card-title-checkout" dir={t.direction}>{title}</p>
                        <p className="card-number-checkout" dir={t.direction}>{number}</p>
                        <p className="card-price" dir={t.direction}>
                            {price}
                            <span className="monthly-text">{monthly}</span>
                        </p>

                        {features.map((feature, index) => (
                            <p key={index} className={`card-feature ${isArabic ? 'arabic-feature' : 'english-feature'}`}>
                                {isArabic ? (
                                    <>
                                        {feature}
                                        {(redFeatures && redFeatures.includes(index)) ? (
                                            <MdCancel className="feature-icon red-icon" />
                                        ) : (
                                            <MdCheckCircle className="feature-icon blue-icon" />
                                        )}
                                    </>
                                ) : (
                                    <>
                                        {(redFeatures && redFeatures.includes(index)) ? (
                                            <MdCancel className="feature-icon red-icon" />
                                        ) : (
                                            <MdCheckCircle className="feature-icon blue-icon" />
                                        )}
                                        {feature}
                                    </>
                                )}
                            </p>
                        ))}

                        <div className="bottom-div">
                            <div className="total" dir={t.direction}>
                                <p className="total-title">{t.total}</p>
                                <p className="total-num" dir={t.direction}>{price}</p>
                            </div>
                            <div>
                                <button className="subscription-btn">{t.purchaseSubscription}</button>
                            </div>
                            <p className="terms">
                                {t.agreeTo} <a href="/terms" className="terms-link">{t.termsAndConditions}</a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <Footer toggleDarkMode={toggleDarkMode} toggleDarkTheme={toggleDarkTheme} />
        </>
    );
};

export default Checkout;



// import React, { useContext, useState } from "react";
// import { useLocation } from "react-router-dom";
// import { LanguageContext } from '../Main/LanguageContext';
// import translations from '../../Translations';
// import "./Checkout.css";
// import Footer from "../Main/Footer";
// import PaymentPopup from "./PaymentPopup";  // استيراد الـ popup


// const Checkout = ({ toggleDarkMode, toggleDarkTheme }) => {
//     const { language } = useContext(LanguageContext);
//     const t = translations[language];
//     const isArabic = language === "ar";
//     const location = useLocation();
//     const { packageKey } = location.state;

//     const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);  // الحالة للتحكم في وسيلة الدفع
//     const [showPopup, setShowPopup] = useState(false);  // الحالة للتحكم في إظهار الـ popup

//     const packageData = t[packageKey] || {
//         title: "Default Package",
//         number: "N/A",
//         price: "N/A",
//         monthly: "",
//         features: [],
//         redFeatures: [] 
//     };

//     const { title, number, price, monthly, features, redFeatures } = packageData;

//     // التعامل مع اختيار وسيلة الدفع
//     const handlePaymentMethodSelect = (method) => {
//         setSelectedPaymentMethod(method);
//         setShowPopup(true);  // إظهار الـ popup عند اختيار وسيلة الدفع
//     };

//     const handleClosePopup = () => {
//         setShowPopup(false);  // إغلاق الـ popup
//     };

//     return (
//         <>
//             <div className="container">
//                 <div className={`checkout-page ${isArabic ? 'arabic' : 'english'}`}>
//                     <div className="payment-methods">
//                         <h2 dir={t.direction}>{t.paymentMethods}</h2>
//                         <div className="payment-method-labels" dir={t.direction}>
//                             <label className="payment-label">
//                                 <input
//                                     type="radio"
//                                     name="paymentMethod"
//                                     value="stripe"
//                                     className="styled-input"
//                                 />
//                                 <span>{t.method1}</span>
//                             </label>
//                             <label className="payment-label">
//                                 <input
//                                     type="radio"
//                                     name="paymentMethod"
//                                     value="paypal"
//                                     className="styled-input"
//                                     onClick={() => handlePaymentMethodSelect("paypal")}
//                                 />
//                                 <span>{t.method5}</span>
//                             </label>
//                             {/* إضافة باقي خيارات الدفع */}
//                         </div>
//                     </div>

//                     <div className={`selected-package ${isArabic ? 'arabic' : 'english'}`}>
//                         <p className="card-title-checkout" dir={t.direction}>{title}</p>
//                         <p className="card-number-checkout" dir={t.direction}>{number}</p>
//                         <p className="card-price" dir={t.direction}>
//                             {price}
//                             <span className="monthly-text">{monthly}</span>
//                         </p>


//                         <div className="bottom-div">
//                             <button className="subscription-btn">{t.purchaseSubscription}</button>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//             <Footer toggleDarkMode={toggleDarkMode} toggleDarkTheme={toggleDarkTheme} />

//             {/* إظهار الـ popup إذا تم اختيار وسيلة دفع */}
//             {showPopup && (
//                 <PaymentPopup
//                     paymentMethod={selectedPaymentMethod}
//                     onClose={handleClosePopup}
//                 />
//             )}
//         </>
//     );
// };

// export default Checkout;
