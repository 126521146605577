import React, { useContext, useState } from 'react';
import { LanguageContext } from '../Main/LanguageContext';
import translations from '../../Translations';
import '../../styles/Contact.css';
import Lottie from 'lottie-react';
import successAnimation from '../../assets/success.json';
import '../../firebase';

const Contact = ({toggleDarkMode}) => {
    const [inputvalue1, setInputvalue1] = useState('');
    const [inputvalue2, setInputvalue2] = useState('');
    const [inputvalue3, setInputvalue3] = useState('');
    const [inputvalue4, setInputvalue4] = useState('');

    const [showPopup, setShowPopup] = useState(false);

    const setDataToFirestore = async (e) => {
    e.preventDefault();
    try {
        // const timestamp = new Date().getTime();
        // const date = new Date().toLocaleString();
      
        const timestamp = new Date(); 
        // const formattedDate = timestamp.toLocaleString();

        const response = await fetch('https://us-central1-libya-d369a.cloudfunctions.net/app/submitForm', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                name: inputvalue1,
                email: inputvalue2,
                message: inputvalue3,
                phone: inputvalue4,
                // timestamp: date,
                // timestamp: timestamp,
                // date: date,
                timestamp: timestamp, 
                // date: formattedDate
            }),
        });

        if (response.ok) {
            setShowPopup(true);
            setInputvalue1('');
            setInputvalue2('');
            setInputvalue3('');
            setInputvalue4('');
        } else {
            console.error('Failed to submit form');
        }
    } catch (error) {
        console.error("Error: ", error);
    }
};
 

const handleClosePopup = () => {
        setShowPopup(false);
    };

    const { language } = useContext(LanguageContext);
    const t = translations[language];

    return (
        <div className={`contact-main ${language === 'ar' ? 'arabic' : 'english'}`} id="contact">
            <div className="contact-container">
                <h1 className="contact-title">
                    {t.contactTitle}
                    <span className="specialTxt">{t.contactSpecialTxt}</span>
                </h1>
                <p className="contact-paragraph">{t.contactDescription}</p>

                <div className="contact-form">
                    <form 
                        method="POST" 
                        className="contact-form" 
                        onSubmit={setDataToFirestore}
                        dir={t.direction}>
                        <div className="form-row">
                            <input
                                type="text"
                                placeholder={t.fullNamePlaceholder}
                                className={`form-input half-width ${toggleDarkMode ? 'bg-dark dark-contact' : ''}`}
                                name="full_name"
                                value={inputvalue1}
                                onChange={(e) => setInputvalue1(e.target.value)}
                                required
                            />
                            <input
                                type="text"
                                placeholder={t.phonePlaceholder}
                                className={`form-input half-width ${toggleDarkMode ? 'bg-dark dark-contact' : ''}`}
                                name="Phone_num"
                                value={inputvalue4}
                                onChange={(e) => setInputvalue4(e.target.value)}
                                required
                            />
                        </div>
                        <input
                            type="email"
                            placeholder={t.emailPlaceholder}
                            className={`form-input ${toggleDarkMode ? 'bg-dark dark-contact' : ''}`}
                            name="email"
                            value={inputvalue2}
                            onChange={(e) => setInputvalue2(e.target.value)}
                            required
                        />
                        <textarea
                            placeholder={t.messagePlaceholder}
                            className={`form-textarea ${toggleDarkMode ? 'bg-dark dark-contact' : ''}`}
                            name="message"
                            value={inputvalue3}
                            onChange={(e) => setInputvalue3(e.target.value)}
                            required
                        ></textarea>
                        <button type="submit" className="send-btn">
                            {t.sendButton}
                        </button>
                        

                    </form>
                </div>
            </div>

            {showPopup && (
        
                <div className="popup-overlay">
                <div className={`popup-card ${language === 'ar' ? 'arabic' : 'english'}`}>
                    <Lottie animationData={successAnimation} className="popup-icon" />
                    <p className="popup-message">{t.successMessage}</p>
                    <p className="popup-submessage">{t.successMessageTxt}</p>
                    <button className="popup-button" onClick={handleClosePopup}>{t.successMessageBtn}</button>
                </div>
            </div>
            )}
        </div>
    );
};

export default Contact;
