import React, { useContext } from "react";
import "../styles/PricingCard.css";
import { MdCheckCircle, MdCancel } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { LanguageContext } from "./Main/LanguageContext";
import translations from "../Translations";

const PricingCard = ({ packageKey, title, number, price, monthly, features, image, redFeatures = [], toggleDarkMode }) => {
  const { language } = useContext(LanguageContext);
  const t = translations[language];
  const isArabic = language === "ar";
  const navigate = useNavigate();

  const handleSubscribe = () => {
    navigate('/checkout', { state: { packageKey } });
  };

  return (
    
    <div className={`PricingCard ${isArabic ? "arabic" : "english"} ${toggleDarkMode ? 'bg-dark' : ''}`}>
      {image && (
        <div className="card-image-container">
          <img src={image} alt="" className="card-image" />
        </div>
      )}

      <header>
        <p className="card-title">{title}</p>
        <p className="card-number" dir={t.direction}>{number}</p>
        <p className="card-price" dir={t.direction}>{price}
          <span className="monthly-text">{monthly}</span>
        </p>
      </header>
      <div className="card-features">
        <button className={`card-btn ${toggleDarkMode ? 'bg-dark' : ''}`}>
        {/* <button className="card-btn" onClick={handleSubscribe}> */}
          {t.subscribe}
        </button>
        {features.map((feature, index) => (
          <p key={index} className="card-feature">
            {isArabic ? (
              <>
                {feature}
                {redFeatures?.includes(index) ? (
                  <MdCancel className="feature-icon red-icon" />
                ) : (
                  <MdCheckCircle className="feature-icon blue-icon" />
                )}
              </>
            ) : (
              <>
                {redFeatures?.includes(index) ? (
                  <MdCancel className="feature-icon red-icon" />
                ) : (
                  <MdCheckCircle className="feature-icon blue-icon" />
                )}
                {feature}
              </>
            )}
          </p>
        ))}
      </div>
    </div>
  );
};

export default PricingCard;
